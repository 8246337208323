import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({filtersForData, setFiltersForData, labels, datafor, compliantData }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "obligations",
        data: datafor,
        backgroundColor: [
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 99, 132, 0.8)",    
          "rgba(54, 162, 235, 0.8)"
        ],
        hoverBackgroundColor: [
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const label = data.labels[elementIndex];
        setFiltersForData({...filtersForData, compliant: label})
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  return (
      <Doughnut data={data} options={options} />
  );
};

export default DoughnutChart;
