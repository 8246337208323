import { Text } from '@mantine/core'
import React from 'react'

const OverDueCompliance=()=> {
  return (
    <div>
      <Text className='font-semibold text-3xl m-4 '>overdue obligations</Text> 
    </div>
  )
}

export default OverDueCompliance