import { Button, Radio, Select, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { addUser } from "../apis/userApis";

const AddUserModal = ({ buId, setUserModal }) => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    enabled: true,
    department: 0,
    role: "",
    password: "",
    businessUnit: buId,
  });

  const addServiceProvider = async (e) => {
    e.preventDefault();
    try {
      const response = await addUser({
        name: userData.name,
        email: userData.email,
        role: userData.role,
        department: userData.department,
        password: userData.password,
        enabled: userData.enabled,
        businessUnit: parseInt(userData.businessUnit),
      });
      if (response.success) {
        setUserModal(false);
        window.location.reload();
      }
    } catch (error) {
      alert("error adding user");
    }
  };
  console.log(userData);
  return (
    <>
      <div className="grid grid-cols-2 m-4 gap-4">
        <TextInput
          required
          label="user name"
          type="text"
          value={userData.name}
          onChange={(e) => setUserData({ ...userData, name: e.target.value })}
        />
        <TextInput
          required
          label="email"
          type="email"
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
        />
        <TextInput
          required
          label="password"
          type="text"
          value={userData.password}
          onChange={(e) =>
            setUserData({ ...userData, password: e.target.value })
          }
        />
        <Select
          label="userRole"
          required
          placeholder="select user role"
          value={userData.role}
          onChange={(val) => setUserData({ ...userData, role: val })}
          data={[
            { label: "Admin", value: "Ob-Admin" },
            { label: "CEO", value: "Ob-CEO" },
            { label: "Editor", value: "Ob-Editor" },
            { label: "Agreement-Editor", value: "Ag-Editor" },
            { label: "COO", value: "Ob-COO" },
            { label: "CXO", value: "Ob-CXO" },
            { label: "DeptAdmin", value: "Ob-DeptAdmin" },
            { label: "DeptUser", value: "Ob-DeptUser" },
            { label: "Auditor", value: "Ob-Auditor" },
          ]}
        />
        {userData.role !== "Ob-Admin" &&
          userData.role !== "Ob-COO" &&
          userData.role !== "Ob-Auditor" &&
          userData.role !== "Ob-Editor" &&
          userData.role !== "Ag-Editor" &&
          userData.role !== "Ob-CEO" && (
            <Select
              label="department"
              required
              value={userData.department}
              onChange={(val) => setUserData({ ...userData, department: val })}
              data={[
                { label: "FINANCE", value: 1 },
                { label: "HR", value: 2 },
                { label: "IT", value: 3 },
                { label: "TAXATION", value: 4 },
              ]}
            />
          )}

        <Radio.Group
          label="User Permission"
          // description="This is a description"
          spacing="xs"
          withAsterisk
          value={userData.enabled + ""}
          onChange={(val) =>
            val === "true"
              ? setUserData({ ...userData, enabled: true })
              : setUserData({ ...userData, enabled: false })
          }
        >
          <Radio value="true" label="Enabled" />
          <Radio value="false" label="Disabled" />
        </Radio.Group>
        <div className="flex justify-between">
          <div></div>
          <Button className="mt-4" onClick={(e) => addServiceProvider(e)}>
            add user
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddUserModal;
