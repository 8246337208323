import { Button, Radio, Select, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { addBu, getBuDetails, updateBuDetails } from "../apis/userApis";

const AddBuModal = ({setEditBuId, setBuModal, editBuId }) => {
  const [buData, setBuData] = useState({
    name: "",
    enabled: true,
  });

  useEffect(()=>{
    const getBuDetail = async()=>{
      const budetails = await getBuDetails({ id : editBuId, page: 1 });
      setBuData(budetails.buDetails)
    }
    if(editBuId>0){
      getBuDetail();
    }  
  },[]);
  const addbu = async (e) => {
    e.preventDefault();
    const response = await addBu({
      name: buData.name,
      enabled: buData.enabled,
    });
    if (response.success) {
      setBuModal(false);
      window.location.reload();
    }
  };
  const updateBu = async(e)=>{
    e.preventDefault();
    const response  = await updateBuDetails({id: editBuId, enabled: buData.enabled, name: buData.name});
    if (response.success) {
      setBuModal(false);
      setEditBuId(0);
      window.location.reload();
    }
  }
  console.log(buData);
  return (
    <div>
      <TextInput
        required
        label="business unit name"
        value={buData.name}
        onChange={(e) => setBuData({ ...buData, name: e.target.value })}
      />
      <Radio.Group
        label="User Permission"
        spacing="xs"
        withAsterisk
        value={buData.enabled + ""}
        onChange={(val) =>
          val === "true"
            ? setBuData({ ...buData, enabled: true })
            : setBuData({ ...buData, enabled: false })
        }
      >
        <Radio value="true" label="Enabled" />
        <Radio value="false" label="Disabled" />
      </Radio.Group>
      <Button
        onClick={(e) => {if(editBuId>0){updateBu(e)} else {addbu(e)}} }
        className="mt-4 flex justify-end"
        variant="filled"
      >{editBuId>0 ? "update": "add"}
      </Button>
    </div>
  );
};

export default AddBuModal;
