import React, { useEffect, useState } from "react";
import { getSignedAgreementList } from "../apis/userApis";
import { Pagination, Table } from "@mantine/core";

const SignedAgreementListModal = ({
  setSelectedAgreementData,
  setSelectedAgreementId,
  setShowModal,
}) => {
  const [configs, setConfigs] = useState({
    agreementsList: [],
    loading: true,
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    const signedlist = async () => {
      const SignedagreementList = await getSignedAgreementList();
      console.log(SignedagreementList.agreementsList);
      setConfigs({
        agreementsList: SignedagreementList.agreementsList,
        loading: false,
      });
    };
    signedlist();
  }, []);

  const renderTableRows = () => {
    if (configs.agreementsList.length === 0) return null;
    const startIndex = (page - 1) * 10;
    const endIndex = Math.min(startIndex + 10, configs.agreementsList.length);
    const rows = [];

    // console.log("agreementId: ", connectedAgreementId);
    for (let i = startIndex; i < endIndex; i++) {
      const agreement = configs.agreementsList[i];
      rows.push(
        <tr key={agreement.id}>
          <td>
            <input
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedAgreementId(agreement.id);
                  setSelectedAgreementData(agreement);
                  setShowModal(false);
                }}
              type="checkbox"
            />
          </td>
          <td>{agreement.id}</td>
          <td>{agreement.typeOfAgreement}</td>
          <td>{agreement.location}</td>
          <td>{agreement.status}</td>
          <td>{agreement.secondParty.name}</td>
          <td>{agreement.dueDateOfContract}</td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <>
      {!configs.loading && configs.agreementsList.length > 0 && (
        <div
          className="flex flex-col"
          style={{
            overflowX: "scroll",
            scrollbarWidth: "none",
            width: "100%",
          }}
        >
          <Table striped>
            <thead>
              <tr>
                <th>Select</th>
                <th>Reference No.</th>
                <th>Type</th>
                <th>Location</th>
                <th>Status</th>
                <th>Second Party</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody id="tablebody">{renderTableRows()}</tbody>
          </Table>
        </div>
      )}
      {!configs.loading && configs.agreementsList.length > 0 && (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            total={Math.ceil(configs.agreementsList.length / 10)}
          />
        </div>
      )}
    </>
  );
};

export default SignedAgreementListModal;
