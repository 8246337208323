import React, { useState } from "react";
import AppHeader from "./Header";
import {
  AppShell,
  Header,
  Navbar,
  ActionIcon,
  ScrollArea,
} from "@mantine/core";
import {
  IconBell,
  IconHome,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarRightCollapse,
  IconLock,
  IconPlus,
  IconProgress,
  IconTimeline,
  IconUsers,
} from "@tabler/icons-react";
import { IconFile } from "@tabler/icons-react";

const SideNav = ({ appContent }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  return (
    <>
      <AppShell
        navbar={
          <Navbar
            style={{ backgroundColor: "#F1F4FA" }}
            width={{ base: sidebarCollapsed ? 50 : 240 }}
            height="calc(100vh - 65px)"
          >
            <Navbar.Section grow component={ScrollArea}>
              <div className="flex flex-col">
                <div className="flex justify-end mt-2">
                  <ActionIcon
                    color="blue"
                    onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                    style={{ cursor: "pointer", marginRight: "9%" }}
                  >
                    {sidebarCollapsed ? (
                      <IconLayoutSidebarRightCollapse size={25} />
                    ) : (
                      <IconLayoutSidebarLeftCollapse size={25} />
                    )}
                  </ActionIcon>
                </div>
                {!sidebarCollapsed && (
                  <div className="flex flex-col p-3">
                    <div className="space-y-3">
                      <div className="flex-1">
                        <ul className=" pb-4 space-y-3 text-sm">
                          {userData.role !=="Ob-Auditor" &&
                          <li className="rounded-sm">
                            <a
                              href="/dashboard"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconHome
                              style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25}
                              />
                              <span style={{ fontWeight: "500" }}>
                                dashboard
                              </span>
                            </a>
                          </li>}
                          <li className="rounded-sm">
                            <a
                              href="/notifications"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconBell
                              style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25}
                              />
                              <span style={{ fontWeight: "500" }}>
                                notifications
                              </span>
                            </a>
                          </li>
                          {(userData.role === "Ob-Admin" || userData.role === "Ob-Editor" || userData.role ==="Ag-Editor") && 
                          <li className="rounded-sm">
                            <a
                              href="/new"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconPlus style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25} />
                              <span style={{ fontWeight: "500" }}>
                                new obligation
                              </span>
                            </a>
                          </li>}
                          {(userData.role === "Ob-DeptUser" || userData.role === "Ob-DeptAdmin"  ) && 
                          <li className="rounded-sm">
                            <a
                              href="/assigned"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconFile style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25} />
                              <span style={{ fontWeight: "500" }}>
                                my obligations
                              </span>
                            </a>
                          </li>}
                          {(userData.role === "Ob-DeptUser" || userData.role === "Ob-DeptAdmin"  ) && 
                          <li className="rounded-sm">
                            <a
                              href="/actioned"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconProgress style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25} />
                              <span style={{ fontWeight: "500" }}>
                                actioned obligations
                              </span>
                            </a>
                          </li>}
                          {(userData.role !== "Ob-Auditor" || userData.role !== "Ob-DeptUser" ) && 
                          <li className="rounded-sm">
                            <a
                              href="/obligationtracker"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconTimeline style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25}/>
                              <span style={{ fontWeight: "500" }}>
                                obligations tracker
                              </span>
                            </a>
                          </li>}
                          {(userData.role ==="Ob-Editor" || userData.role ==="Ob-Admin" || userData.role === "Ag-Editor") && 
                          <li className="rounded-sm">
                            <a
                              href="/docslocker"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconLock style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25}/>
                              <span style={{ fontWeight: "500" }}>
                                agreements locker
                              </span>
                            </a>
                          </li>}
                          {(userData.role === "Ob-Admin" || userData.role === "Ob-DeptAdmin"  ) && 
                          <li className="rounded-sm">
                            <a
                              href="/team"
                              className="flex items-center p-2 space-x-4 rounded-md"
                            >
                              <IconUsers style={{backgroundColor: "rgb(231, 245, 255)", padding:"2px"}}
                                color="rgb(34, 139, 230)"
                                size={25} />
                              <span style={{ fontWeight: "500" }}>
                                team manager
                              </span>
                            </a>
                          </li>}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Navbar.Section>
          </Navbar>
        }
        header={
          <Header height={65} style={{ background: "#FFFFFF" }}>
            <AppHeader />
          </Header>
        }
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            minHeight: "calc(100vh - 100px)",
          }}
        >
          {appContent}
        </div>
      </AppShell>
    </>
  );
};

export default SideNav;

// 21
{
  /* <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 17V1m0 0L1 4m3-3 3 3m4-3h6l-6 6h6m-7 10 3.5-7 3.5 7m-6.125-2H16"/>
</svg> */
}

// 31
// <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 17 14">
//     <path d="M16 2H1a1 1 0 0 1 0-2h15a1 1 0 1 1 0 2Zm0 6H1a1 1 0 0 1 0-2h15a1 1 0 1 1 0 2Zm0 6H1a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2Z"/>
// </svg>
