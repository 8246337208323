import {
  ActionIcon,
  Badge,
  Modal,
  Pagination,
  Table,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getComplianceStatus } from "../apis/userApis";
import BusinessUnitName from "../componenets/getBuName";
import {
  getDepartmentText,
  getComplianceBadgeColor,
  infoColor,
  getDateStatus,
  getStatusColor,
  getStatusText,
} from "./utilities";
import { IconEye, IconHistory } from "@tabler/icons-react";
import DoughnutChart from "../componenets/DoughnutChart";
import DoughnutChart3 from "../componenets/DoughnutChart3";
import DoughnutChart2 from "../componenets/DoughnutChart2";
import DoughnutChart4 from "../componenets/DoughnutChart4";
import { useNavigate } from "react-router-dom";
import ObligationTranHistoryModal from "./ObligationTranHistoryModal";
import { IconInfoCircle } from "@tabler/icons-react";

const DashboardCompliance = () => {
  const [uiConfig, setUiConfig] = useState({
    obligations: null,
    loading: true,
  });
  const [countConfig, setCountConifg] = useState({
    page: 1,
    count: 0,
  });
  const [filtersForData, setFiltersForData] = useState({
    compliant: null,
    businessUnit: null,
    department: null,
    connectedAgreement: null,
  });

  const [compliantData, setCompliantData] = useState(null);
  const [businessUnitData, setBusinessUnitData] = useState(null);
  const [departmentData, setDepartmentData] = useState(null);
  const [agreementData, setAgreementData] = useState(null);
  const [ahModal, setAhModal] = useState(false);
  const [ahId, setAhId] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const filterdata = Object.fromEntries(
        Object.entries(filtersForData).filter(([key, value]) => value !== null)
      );
      const response = await getComplianceStatus({
        filters: filterdata,
        page: countConfig.page,
      });
      console.log(response);
      setUiConfig({
        loading: false,
        obligations: response.obligations,
      });
      setCountConifg({
        ...countConfig,
        count: response.count,
      });
      setCompliantData(response.compliantStatusCount);
      setBusinessUnitData(response.businessUnitCount);
      setDepartmentData(response.departmentCount);
      setAgreementData(response.agreementCount);
    };
    getData();
  }, [countConfig.page, filtersForData]);
  let sr = (countConfig.page - 1) * 10 + 1;
  const onViewClick = (id) => {
    navigate(`/viewobligation/${id}`);
  };

  return (
    <>
      <div className="m-4">
        <Text className="m-4 text-3xl font-semibold">compliance status</Text>
        <div className="grid grid-cols-2 gap-4">
          {compliantData !== null && (
            <div>
              <Text className="text-center">compliance status </Text>
              <div
                className="flex justify-center"
                style={{
                  width: "100%",
                  height: "200px",
                }}
              >
                <DoughnutChart
                  compliantData={compliantData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={compliantData.map((item) => item.compliant)}
                  datafor={compliantData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
          {businessUnitData !== null && (
            <div>
              <Text className="text-center">business unit </Text>
              <div
                className="flex justify-center"
                style={{
                  width: "100%",
                  height: "200px",
                }}
              >
                <DoughnutChart2
                  businessUnitData={businessUnitData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={businessUnitData.map((item) => item.name)}
                  datafor={businessUnitData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
          {departmentData !== null && (
            <div>
              <Text className="text-center">department </Text>
              <div
                className="flex justify-center"
                style={{
                  width: "100%",
                  height: "200px",
                }}
              >
                <DoughnutChart3
                  departmentData={departmentData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={departmentData.map((item) =>
                    getDepartmentText(item.department)
                  )}
                  datafor={departmentData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
          {agreementData !== null && (
            <div>
              <Text className="text-center">Connected agreement </Text>
              <div
                className="flex justify-center"
                style={{
                  width: "100%",
                  height: "200px",
                }}
              >
                <DoughnutChart4
                  agreementData={agreementData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={agreementData.map(
                    (item) => item.connectedAgreement + ""
                  )}
                  datafor={agreementData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {uiConfig.obligations !== null && (
        <>
          <Table striped>
            <thead className="text-center">
              <tr>
                <th style={{ textAlign: "center" }}>sr. no.</th>
                <th style={{ textAlign: "center" }}>title</th>
                <th style={{ textAlign: "center" }}>obligation</th>
                <th style={{ textAlign: "center" }}>agreement type</th>
                <th style={{ textAlign: "center" }}>business unit</th>
                <th style={{ textAlign: "center" }}>second party</th>
                <th style={{ textAlign: "center" }}>department</th>
                <th style={{ textAlign: "center" }}>compliance</th>
                <th style={{ textAlign: "center" }}>status</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {uiConfig.obligations.map((obligation) => (
                <tr key={obligation.id} style={{ textAlign: "center" }}>
                  <td>{sr++}</td>
                  <td>{obligation.title}</td>
                  <td>{obligation.desc}</td>
                  <td>{obligation.extraCol1}</td>
                  <td>{obligation.businessUnit}</td>
                  <td>{obligation.connectedParty}</td>
                  <td>{getDepartmentText(obligation.department)}</td>
                  <td>
                    <Badge
                      variant="light"
                      color={getComplianceBadgeColor(obligation.compliant)}
                    >
                      {obligation.compliant}
                    </Badge>
                  </td>
                  <td>
                    <Badge variant="light" color={getStatusColor(getDateStatus(obligation.createdAt, obligation.duedate, obligation.reminderTime ))}>
                      {getStatusText(getDateStatus(obligation.createdAt, obligation.duedate, obligation.reminderTime ))}
                    </Badge>
                  </td>
                  <td>
                    <div className="flex flex-row mr-4">
                      <ActionIcon
                        onClick={() => {
                          onViewClick(obligation.id);
                        }}
                        color="white"
                      >
                        <IconEye size={24} />
                      </ActionIcon>
                      {/* )} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-row mr-4">
                      <ActionIcon
                        onClick={() => {
                          setAhId(obligation.id);
                          setAhModal(true);
                        }}
                        color="white"
                      >
                        <IconHistory size={24} />
                      </ActionIcon>

                      {/* )} */}
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div className="flex flex-row mr-4">
                      {obligation.extraCol3 !== "" && (
                        <ActionIcon color="white">
                          <IconInfoCircle
                            color={infoColor(obligation.extraCol3)}
                            size={24}
                          />
                        </ActionIcon>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="flex flex-row justify-center my-4">
            <Pagination
              page={countConfig.page}
              onChange={(val) => setCountConifg({ ...countConfig, page: val })}
              total={Math.ceil(countConfig.count / 10)}
            />
          </div>
        </>
      )}
      <Modal
        opened={ahModal}
        onClose={() => {
          setAhModal(false);
          setAhId(0);
        }}
        title="oblligation action history"
        size="calc(80vw)"
      >
        <ObligationTranHistoryModal id={ahId} />
      </Modal>
    </>
  );
};

export default DashboardCompliance;
