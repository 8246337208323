import { Avatar, Menu, Text } from '@mantine/core';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AppHeader = ()=> {
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [opened, setOpened]= useState(true);
    const logoutHandler = () => {
      localStorage.clear();
      window.location.reload();
      navigate('/login', { replace: true });
    };
    
  return (
    <div className="flex flex-row justify-between mx-8 my-2">
      <img
        style={
            {"width":"12rem"}
        }
        src="https://app.truecounsel.in/assets/images/trueCounselLogo.png"
        alt="TrueCounsel"
      />
      <div className="flex">
        {/* <Select
          required
          className="max-w-lg mr-2"
          placeholder="Select Role to login as"
          data={roles.map((role) => ({
            label: role.name,
            value: role.id,
            pass: role,
          }))}
          // label={currentUser.loginAs.name}
          value={currentUser.loginAs.id}
          onChange={(pass) => handleRoleChange(pass)}
        /> */}
        <Menu>
          <Menu.Target>
            <div
              onClick={() => {
                setOpened(!opened);
              }}
              className="flex items-center cursor-pointer"
            >
              {userData.picture?.length ? (
                <Avatar size="md" src={userData.picture} radius="xl" />
              ) : (
                <Avatar size="md" radius="xl">
                  {userData.name[0].toUpperCase()}
                </Avatar>
              )}
              <div className="ml-2 flex flex-col">
                <Text size="md">{userData.name}</Text>
                <Text size="xs">{userData.email}</Text>
              </div>
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={logoutHandler} icon={<svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3"/>
</svg>} >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  )
}

export default AppHeader