import { getBuList, getBuName } from "../apis/userApis";

export function convertToISO(dateString) {
  const date = new Date(dateString);
  return date.toISOString();
}

export function convertFromISO(isoString) {
  const date = new Date(isoString);
  return date.toString();
}

export function existsAndLength(value) {
  return value?.length > 0;
}

export const formatDateString = (inputDateString) => {
  // Create a Date object from the input date string
  const date = new Date(inputDateString);

  // Function to add leading zero if necessary
  const pad = (number, length = 2) => {
    return String(number).padStart(length, "0");
  };

  // Extract and format each part of the date
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = pad(date.getMilliseconds(), 3);

  // Construct the formatted date string
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.001`;

  return formattedDateString;
};

export const getActFrequencyText = (value) => {
  if (value === 1) {
    return "one-time";
  } else if (value === 2) {
    return "on-going";
  } else if (value === 3) {
    return "reccuring";
  }
};

export const getRecFrequencyText = (value) => {
  if (value === 7) {
    return "weekly";
  } else if (value === 30) {
    return "monthly";
  } else if (value === 90) {
    return "quaterly";
  } else if (value === 365) {
    return "annually";
  }
};

export const getDepartmentText = (value) => {
  if (value === 1) {
    return "Finance";
  } else if (value === 2) {
    return "HR";
  } else if (value === 3) {
    return "IT";
  } else if (value === 4) {
    return "Taxation";
  }
};

export const getDepartmentNumber = (value) => {
  if (value === "Finance") {
    return 1;
  } else if (value === "HR") {
    return 2;
  } else if (value === "IT" ) {
    return 3;
  } else if (value === "Taxation") {
    return 4;
  }
};

export const getFutureDate = async(currentDate, daysToAdd) => {
  const date = new Date(currentDate);
  date.setDate(date.getDate() + daysToAdd);

  return date;
};

export const getStatusText = (value) => {
  if (value === 0) {
    return "upcoming";
  } else if (value === 1) {
    return "due";
  } else if (value === 2) {
    return "overdue";
  }
  return;
};
export const getDateStatus = (createDate, dueDate, reminderDays) => {
  const createDateObj = new Date(createDate);
  const dueDateObj = new Date(dueDate);
  const reminderDateObj = new Date(dueDateObj);
  reminderDateObj.setDate(dueDateObj.getDate() - reminderDays);
  const currentDate = new Date();
  if (createDateObj < currentDate && currentDate < reminderDateObj) {
    return 0;
  } else if (reminderDateObj < currentDate && currentDate < dueDateObj) {
    return 1;
  } else if (currentDate > dueDateObj) {
    return 2;
  }
};

export const getStatusColor = (value) => {
  if (value === 0) {
    return "indigo";
  } else if (value === 1) {
    return "orange";
  } else if (value === 2) {
    return "red";
  }
  return;
};

export const formatDatetoISO = (isoString) => {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDatetoIST = (inputDateString) => {
  const date = new Date(inputDateString + "Z");

  // Convert the date to the desired time zone (IST in this case)
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata", // This is for GMT+0530 (IST)
    timeZoneName: "short",
  };

  // Format the date to the desired format
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
};

export const getDays = (inputDate) => {
  const date = new Date(inputDate);
  const today = new Date();
  const differenceInTime = today.getTime() - date.getTime() ;
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const getComplianceBadgeColor =(value)=>{
  if (value === "compliant") {
    return "indigo";
  } else if (value === "partially-compliant") {
    return "orange";
  } else if (value === "non-compliant") {
    return "red";
  }
}


export const converDateToISO =(value)=>{
const originalDate = new Date(value);
const year = originalDate.getFullYear();
const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const day = String(originalDate.getDate()).padStart(2, '0');
const hours = String(originalDate.getHours()).padStart(2, '0');
const minutes = String(originalDate.getMinutes()).padStart(2, '0');
const seconds = String(originalDate.getSeconds()).padStart(2, '0');
const milliseconds = String(originalDate.getMilliseconds()).padStart(3, '0');

// Formatted date and time
const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

return formattedDate;
}

export const convertToISO8601=(dateString)=>{
  // Split the date and time parts
  let [datePart, timePart] = dateString.split(' ');

  // Combine date and time with 'T' and append 'Z'
  let iso8601String = `${datePart}T${timePart}Z`;

  return iso8601String;
}

// export async function buData(params) {
//   const response  = await getBuList() 
// }

export function generateRandomColors(numColors) {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    colors.push(`rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.7)`);
  }
  return colors;
}

export const infoColor = (value) => {
  switch (value) {
    case "1":
      return "red"; // Or any other color you want
    case "2":
      return "orange";
    case "3":
      return "green";
    default:
      return "gray"; // Default color if none of the values match
  }
};

